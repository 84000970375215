<template>
  <v-app>
    <dashboard-core-app-bar />
    <router-view class="mt-12" />
    <dashboard-core-footer />
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components: {
      DashboardCoreAppBar: () => import('../components/base/AppBar'),
      DashboardCoreFooter: () => import('../components/base/Footer'),
    },
    props: {
      source: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      expandOnHover: true,
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          group: '',
          title: this.$t('avatar'),
          children: [
            {
              href: '',
              title: this.$t('my-profile'),
            },
            {
              to: '',
              title: this.$t('edit-profile'),
            },
            {
              to: '',
              title: this.$t('settings'),
            },
          ],
        }
      },
    },
    created () {
      // this.$vuetify.theme.dark = true
    },
  }
// };
</script>
